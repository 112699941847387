import '../styles/ContactPage.scss';

const Contact = () => {
    return (
        <>
            <div className="container">
                <h1 className="text-center mb-2">Container Anfragen</h1>
                <h2 className="text-center mb-2">Einfach, schnell und zuverlässig</h2>
                <h3 className="text-center mb-5">Alles, was Sie wissen müssen, um den richtigen Container für Ihr
                    Projekt zu bekommen</h3>
                <div className="row">
                    <div className="col-12">
                        <p>
                            Sie haben ein Projekt und benötigen einen Container? Wir bieten Ihnen einen flexiblen,
                            schnellen und unkomplizierten Service, der sich ganz nach Ihren Bedürfnissen richtet. Ganz
                            gleich, ob Sie kurzfristig einen Container für eine Baustelle, eine Entrümpelung oder einen
                            Umzug benötigen – wir sind Ihr zuverlässiger Partner!
                        </p>
                        <p>
                            Unsere Container stehen Ihnen bis zu 4 Wochen zur Verfügung, sodass Sie ausreichend Zeit
                            haben, Ihr Projekt in Ruhe umzusetzen. Sollte Ihr Bedarf länger bestehen, können Sie die
                            Mietdauer problemlos verlängern – ohne komplizierte Verträge oder Bürokratie. Bei uns zählt
                            Ihre Flexibilität!
                        </p>
                        <p>
                            Sie wählen den passenden Container aus und bestimmen, wann und wo er geliefert wird. Egal,
                            ob für ein kleines oder großes Vorhaben, wir haben den richtigen Container in verschiedenen
                            Größen für jede Art von Abfall oder Material. So stellen wir sicher, dass Sie immer genug
                            Platz haben, um Ihr Projekt effizient und stressfrei abzuschließen.
                        </p>
                        <p>
                            Der Ablauf ist denkbar einfach: Füllen Sie einfach das Anfrageformular aus und teilen Sie
                            uns Ihre Wünsche mit. Wir kümmern uns um den Rest – von der Lieferung des Containers bis hin
                            zur unkomplizierten Abholung. Sollte sich Ihr Bedarf ändern, können Sie jederzeit auf unsere
                            Flexibilität zählen.
                        </p>
                        <p>
                            <strong>Warum bei uns anfragen?</strong>
                            <ul>
                                <li>Schnelle Lieferung: Wir liefern den Container genau dann, wenn Sie ihn brauchen.
                                </li>
                                <li>Maximale Flexibilität: Container können bis zu 4 Wochen bleiben, mit der Möglichkeit
                                    zur Verlängerung.
                                </li>
                                <li>Einfache Abwicklung: Kein Papierkram, keine langen Wartezeiten – alles geht schnell
                                    und unkompliziert.
                                </li>
                                <li>Faire Preise: Bei uns zahlen Sie nur für die Zeit, in der Sie den Container wirklich
                                    benötigen.
                                </li>
                            </ul>
                        </p>
                        <p>
                            Jetzt Container anfragen und Ihr Projekt in die Tat umsetzen! Füllen Sie einfach unser
                            Anfrageformular aus und wir kümmern uns um den Rest. Profitieren Sie von unserer jahrelangen
                            Erfahrung und dem exzellenten Service.
                        </p>
                        <a className="btn btn-primary mt-3" href="tel:+491726301892">Jetzt anrufen +49 1726 30 18 92</a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Contact;
