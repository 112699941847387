function ContainerService() {
    return (
        <div className="container-service bg-gray">
            <div className="container spacing-section">
                <h2 className="text-center mb-2">Flexibilität und Spontanität – Ihr Containerservice</h2>
                <h3 className="text-center mb-5">Schnell, flexibel und auf Ihre Bedürfnisse abgestimmt</h3>
                <div className="row">
                    <div className="col-12">
                        <p>
                            Benötigen Sie kurzfristig einen Container für Ihr Projekt? Unser flexibler Containerservice passt sich ganz Ihrem Zeitrahmen an. Ob für einen Umzug, eine Baustelle oder eine Entrümpelung – Sie entscheiden, wie lange der Container bei Ihnen bleibt.
                        </p>
                        <p>
                            Unsere Container können bis zu 4 Wochen bei Ihnen stehen, und sollte Ihr Bedarf länger bestehen, passen wir die Mietdauer ganz nach Ihren Wünschen an. Alles ganz unkompliziert und flexibel!
                        </p>
                        <p>
                            Neugierig, wie schnell und einfach Sie einen Container bei uns mieten können? <a href="/container-anfrage" className="btn-link">Erfahren Sie mehr auf unserer detaillierten Seite!</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContainerService;
