import '../styles/WasteList.scss'
import {useRef} from "react";


function GroundWasteList() {
    const imageRef = useRef(null);

    const items = [
        {name: "Muttererde", image: "assets/img/baumischabfall.jpg",},
        {name: "Erde", image: "assets/img/glass.jpg", }]

    const noItems = [
        // {name: "Warden", image: "assets/img/baumischabfall.jpg", },
        {name: "Grass", image: "assets/img/baumischabfall.jpg", },
        {name: "Steine", image: "assets/img/glass.jpg", }];
    return (
        <div className="wastelist">
            <div className="container spacing-section">
                <h2 className="text-center mb-2">Grundabfälle</h2>
                <h3 className="text-center mb-5">Das darf rein:</h3>
                <div className="row">
                    {items.map((item, index) => (
                        <div className="col-6 col-sm-4 col-lg-3 col-xl-2 m-auto" key={index}>
                            <a className="waste-item" href={item.href}>
                                <div className="text-container">
                                    <h3 dangerouslySetInnerHTML={{ __html: item.name }}></h3>
                                    <small dangerouslySetInnerHTML={{ __html: item.description }}></small>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>

                <h3 className="text-center">Das darf NICHT rein:</h3>
                <div className="row">
                    {noItems.map((item, index) => (
                        <div className="col-6 col-sm-4 col-lg-3 col-xl-2 m-auto" key={index}>
                            <a className="waste-item" href={item.href}>
                                <div className="text-container">
                                    <h3 dangerouslySetInnerHTML={{ __html: item.name }}></h3>
                                    <small dangerouslySetInnerHTML={{ __html: item.description }}></small>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>

                <button className="btn btn-primary mx-auto d-block" onClick={() => window.location.href = 'tel:+491726301892'}>
                    Jetzt anrufen +49 1726 30 18 92
                </button>
            </div>
        </div>
    );
}

export default GroundWasteList;