import '../styles/WasteList.scss'
import {useRef} from "react";

function WasteList() {
    const imageRef = useRef(null);

    const items = [
        {name: "Baumisch&shy;abfall", image: "assets/img/baumischabfall.jpg", description: "Baustellenabfälle",},
        {name: "Glas", image: "assets/img/glass.jpg", description: "Recycling&shy;fähiges Glas",},
        {name: "Papier", image: "assets/img/paper.jpg", description: "Papier und Karton", },
        {name: "Folie", image: "assets/img/foil.jpg", description: "Verpackungs&shy;folie", },
        {name: "Kunst&shy;stoff", image: "assets/img/plastic.jpg", description: "Plastik&shy;abfälle",},
        {name: "Tapeten&shy;reste", image: "assets/img/wallpaper.jpg", description: "Reste von Tapeten",},
        {name: "Holz&shy;reste", image: "assets/img/wood.jpg", description: "Holz&shy;abfälle", href: "info/holzreste"},
        {name: "Metall", image: "assets/img/metall.jpg", description: "Metall&shy;abfälle",},
        {name: "Möbel", image: "assets/img/furniture.jpg", description: "Alte Möbel&shy;stücke", },
        {name: "Gummi", image: "assets/img/rubber.jpg", description: "Gummi&shy;abfälle", },
    ];
    return (
        <div className="wastelist">
            <div className="container spacing-section">
                <h2 className="text-center mb-2">Misch&shy;abfall | Ge&shy;werbe&shy;abfall</h2>
                <h3 className="text-center mb-5">Das darf rein:</h3>
                <div className="row">
                    {items.map((item, index) => (
                        <div className="col-6 col-sm-4 col-lg-3 col-xl-2 m-auto" key={index}>
                            <a className="waste-item" href={item.href}>
                                <img  ref={imageRef} src={item.image} alt={item.name}/>
                                <div className="text-container">
                                    <h3 dangerouslySetInnerHTML={{ __html: item.name }}></h3>
                                    <small dangerouslySetInnerHTML={{ __html: item.description }}></small>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
                <button className="btn btn-primary mx-auto d-block" onClick={() => window.location.href = 'tel:+491726301892'}>
                    Jetzt anrufen +49 1726 30 18 92
                </button>
            </div>
        </div>
    );
}

export default WasteList;