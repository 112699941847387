import React from 'react';
import '../styles/ContainerSize.scss';
import PropTypes from 'prop-types';

const StatCard = ({value, gradientColors}) => {
    return (
        <div className="col-6 col-sm-4 col-lg-4 col-xl-2 ma-auto container-size">
            <svg viewBox="0 0 600 600">
                <defs>
                    <linearGradient id={`grad-${value}`} x1="0%" y1="100%" x2="100%" y2="0%">
                        <stop offset="0%" stopColor={gradientColors[0]}/>
                        <stop offset="100%" stopColor={gradientColors[1]}/>
                    </linearGradient>
                </defs>
                <path
                    d="M552.424,300l-114.242,112.701l-286.862,0l-103.744,-103.744l0,-121.658l390.438,-0l114.41,112.701Zm-5.698,0.002l-110.185,108.699c0,0 -260.306,0 -283.564,0c0,0 -101.401,-101.401 -101.401,-101.401c0,0 0,-116.001 0,-116.001c0,-0 357.56,-0 384.799,-0c-0,-0 110.351,108.703 110.351,108.703l-0,0Z"
                    fill={`url(#grad-${value})`}
                />
            </svg>
            <h2>{value}<small>m³</small></h2>
        </div>
    );
};

StatCard.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    gradientColors: PropTypes.array.isRequired,
};

const ContainerSize = () => {
    const stats = [
        {value: 5, gradientColors: ['#f403d1', '#64b5f6']},
        {value: 7, gradientColors: ['#f321d7', '#e8b65a']},
        {value: 10, gradientColors: ['#e8b65a', '#24ff72']},
        {value: 12, gradientColors: ['#9a4eff', '#24ff72']},
        {value: 14, gradientColors: ['#64b5f6', '#9a4eff']}
    ];

    return (
        <div className="container spacing-section">
            <h2>Unsere Größen der Absetz&shy;container</h2>
            <div className="row">
                {stats.map((stat, index) => (
                    <StatCard
                        key={index}
                        value={stat.value}
                        gradientColors={stat.gradientColors}
                    />
                ))}
            </div>
        </div>
    );
};

export default ContainerSize;
