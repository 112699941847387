import InfoPage from "../../components/reusable/InfoPage";

const Bioabfall = () => {
    return (
        <>
            <InfoPage
                donts={{
                    headline: "Nicht erlaubt (Baumischabfall):",
                    points: [
                        "Farben",
                        "Lacke",
                        "Lösungsmittel",
                        "Spraydosen",
                        "Dachpappe",
                        "Gips",
                        "Mineralwolle/Glaswolle",
                        "Batterien"
                    ]
                }}
                img={{src: "../assets/img/baumischabfall.jpg", alt: "Baumischabfälle"}}
                description={"Baumischabfall umfasst typische Baustellenabfälle, die aus mineralischen und nicht-mineralischen Stoffen bestehen. Nicht enthalten sind Abfälle, die spezielle Entsorgung erfordern."}
                dos={{
                    headline: "Erlaubt (Baumischabfall):",
                    points: [
                        "Bauschutt",
                        "Kunststoffe",
                        "Tapetenreste",
                        "leere Eimer",
                        "Kabel",
                        "Rohre",
                        "Verpackungen (z. B. von Baustoffen)",
                        "unbehandelte Fensterrahmen mit und ohne Glasresten",
                        "Holzreste",
                        "Betonreste",
                        "Gasbeton (Ytong\u00ae)",
                        "Metalle",
                        "Bleche",
                        "Träger",
                        "Moniereisen",
                        "Heizkörper",
                        "Zimmertüren (nur aus dem Innenbereich)",
                        "Lichtschalter",
                        "leere Kartuschen"
                    ]
                }}
                title={"Baumischabfall"}/>
        </>
    );
};

export default Bioabfall;
