import '../styles/navigation.scss'
import {BrowserRouter, Route, Routes, useLocation} from "react-router-dom";
import Layout from "../pages/Layout";
import Home from "../pages/Home";
import Impress from "../pages/Impress";
import Contact from "../pages/Contact";
import NoPage from "../pages/NoPage";
import {useEffect} from "react";
import Glass from "../pages/info/Glass";
import Papier from "../pages/info/Papier";
import Folie from "../pages/info/Folie";
import Kunststoff from "../pages/info/Kunststoff";
import Tapetenreste from "../pages/info/Tapetenreste";
import Verbundstoff from "../pages/info/Verbundstoff";
import Holzreste from "../pages/info/Holzreste";
import Textilien from "../pages/info/Textilien";
import Metall from "../pages/info/Metall";
import Elektronik from "../pages/info/Elektronik";
import Möbel from "../pages/info/Möbel";
import Keramik from "../pages/info/Keramik";
import Gummi from "../pages/info/Gummi";
import Bioabfall from "../pages/info/Bioabfall";
import Baumischabfall from "../pages/info/Baumischabfall";
import Inquire from "../pages/Inquire";
import ContainerFill from "./ContainerFill";


function Navigation() {
    const ScrollToTop = () => {
        const { pathname } = useLocation();

        useEffect(() => {
            window.scrollTo(0, 0);
        }, [pathname]);

        return null;
    };

    return (
        <>
            <BrowserRouter>
                <ScrollToTop />
                <Routes>
                    <Route path="/" element={<Layout/>}>
                        <Route index element={<Home/>}/>

                        {/* Main */}
                        <Route path="impressum" element={<Impress/>}/>
                        <Route path="kontakt" element={<Contact/>}/>
                        <Route path="container-anfrage" element={<Inquire/>}/>
                        <Route path="container-fuellung" element={<ContainerFill/>}/>

                        {/* Info Pages*/}
                        <Route path="info/glass" element={<Glass/>}/>
                        <Route path="info/baumischabfall" element={<Baumischabfall/>}/>
                        <Route path="info/papier" element={<Papier/>}/>
                        <Route path="info/folie" element={<Folie/>}/>
                        <Route path="info/kunststoff" element={<Kunststoff/>}/>
                        <Route path="info/tapetenreste" element={<Tapetenreste/>}/>
                        <Route path="info/verbundstoffe" element={<Verbundstoff/>}/>
                        <Route path="info/holzreste" element={<Holzreste/>}/>
                        <Route path="info/textilien" element={<Textilien/>}/>
                        <Route path="info/metall" element={<Metall/>}/>
                        <Route path="info/elektronik" element={<Elektronik/>}/>
                        <Route path="info/möbel" element={<Möbel/>}/>
                        <Route path="info/keramik" element={<Keramik/>}/>
                        <Route path="info/gummi" element={<Gummi/>}/>
                        <Route path="info/bioabfall" element={<Bioabfall />}/>

                        <Route path="*" element={<NoPage/>}/>
                    </Route>
                </Routes>
            </BrowserRouter>
        </>
    );
}

Navigation.propTypes = {};

export default Navigation;