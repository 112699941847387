import '../styles/WasteList.scss'

function MetalWasteList() {
    return (
        <div className="wastelist">
            <div className="container spacing-section">
                <h2 className="text-center mb-2">Schrott & Metall</h2>
                <h3 className="text-center mb-5">Bitte telefonisch anfragen</h3>
                <button className="btn btn-primary mx-auto d-block" onClick={() => window.location.href = 'tel:+491726301892'}>
                    Jetzt anrufen +49 1726 30 18 92
                </button>
            </div>
        </div>
    );
}

export default MetalWasteList;