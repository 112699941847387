import '../styles/Impress.scss';

const Impress = () => {
    return (
        <>
            <div className="container spacing-section" id="impress">
                <h1>Impressum</h1>

                <ul>
                    <li><small>Ansprechperson:</small> Thomas Scholten</li>
                    <li><small>Postleitzahl:</small>74080</li>
                    <li><small>Stadt:</small>Heilbronn</li>
                    <li><small>Straße:</small>Im Kreuzgrund 40</li>
                    <li><small>Telefon:</small>+497131/962453</li>
                    <li><small>Handy:</small>+491726301892</li>
                    <li><small>Fax:</small>+497131628497</li>
                    <li><small>Email:</small>th.scholten@t-online.de</li>
                    <li><small>Ust-ld-Nr.:</small>DE451718114</li>
                    <li><small>Steuer-Nummer:</small>65199/16566</li>
                </ul>
            </div>
        </>
    );
};

export default Impress;
